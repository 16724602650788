import './clear.css';
import 'normalize.css/normalize.css';

(() => {

})();


// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/service-worker.js').then(() => null).catch(() => null);
//   });
// }
